import api from '../services/api'
import Helper from './helper'

class StockService {
  get(p, s, pid) {
    if (pid) {
      return api.get(`/ps?${Helper.getPageQuery(p, s)}&pid=${pid}`)
    }
    return api.get(`/ps?${Helper.getPageQuery(p, s)}`)
  }
}

export default new StockService()
