<template>
  <div>
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-select
          icon="fa fa-plus"
          placeholder="过滤产品"
          :clearable="true"
          @change="selectProduct"
          :options="products"
          :option-props="productsOptionProps"
          v-model="selectedProduct"
        ></vxe-select>
      </template>
    </vxe-toolbar>

    <vxe-table
      border
      resizable
      row-key
      show-overflow
      highlight-hover-row
      ref="xTable"
      :data="tableData.rows"
      :loading="loading"
    >
      <vxe-table-column type="seq" width="60"></vxe-table-column>
      <vxe-table-column field="product" title="产品"></vxe-table-column>
      <vxe-table-column field="color" title="颜色"></vxe-table-column>
      <vxe-table-column field="size" title="尺码"></vxe-table-column>
      <vxe-table-column field="count" title="数量"></vxe-table-column>
      <vxe-table-column
        field="updated"
        title="修改时间"
        formatter="dateTime"
      ></vxe-table-column>
    </vxe-table>

    <vxe-pager
      perfect
      :current-page.sync="tableData.page"
      :page-size.sync="tableData.pageSize"
      :total="tableData.totalResult"
      @page-change="pageChange"
    >
    </vxe-pager>
  </div>
</template>

<script>
import StockService from '../services/stock.service'
import ProductService from '../services/product.service'

export default {
  data() {
    return {
      loading: false,
      submitLoading: false,
      tableData: {},
      selectRow: null,
      showEdit: false,
      products: [],
      selectedProduct: '',
      productsOptionProps: { value: 'id', label: 'title' }
    }
  },
  async mounted() {
    this.loading = true
    try {
      const response = await StockService.get()
      this.tableData = response.data
      const productResponse = await ProductService.get(1, 10)
      this.products = productResponse.data.rows
    } catch {
      this.tableData = {}
    }
    this.loading = false
  },
  methods: {
    async pageChange({ currentPage, pageSize }) {
      this.loading = true
      try {
        const response = await StockService.get(currentPage, pageSize)
        this.tableData = response.data
      } catch {
        this.tableData = {}
      }
      this.loading = false
    },
    async selectProduct({ value }) {
      this.loading = true
      try {
        const response = await StockService.get(1, 10, value)
        this.tableData = response.data
      } catch {
        this.tableData = {}
      }
      this.loading = false
    }
  }
}
</script>
